// src/components/FooterComponent.js
import React from 'react';
import { HashLink } from 'react-router-hash-link';

const currentYear = new Date().getFullYear();

function FooterLast() {
  return (
        <>
        <div className="row fs-7 gx-lg-5 mb-4 mb-md-5">
          <div className="col-md-5 mb-3 mb-md-0">
            <a href="/">
              <img src="images/logo.png" width="228" alt="Investors Coach" />
            </a>
            <p className="mt-3">
            Your partner in real estate success. Get experts advice to navigate your investment journey with confidence.
            </p>
          </div>
          <div className="col-6 col-md-auto ms-lg-auto">
            <p className="fw-bold mb-3 mb-md-4">Company</p>
            <ul className="list-unstyled">
              <li>
              <HashLink smooth to="/#experts">Find an Expert</HashLink>
              </li>
              <li>
                <a href="/become-an-expert">Become an Expert</a>
              </li>
              <li>
                <a href="/about">About Us</a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-auto">
            <p className="fw-bold mb-3 mb-md-4">Support</p>
            <ul className="list-unstyled">
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
              <li>
                <a href="/faq">FAQs</a>
              </li>
              {/* <li>
                <a href="/#section1">Sign Up (FIX IT)</a>
              </li> */}
            </ul>
          </div>
          <div className="col-md-auto mt-3 mt-md-0">
            <p className="fw-bold mb-3 mb-md-4">Follow Us</p>
            <ul className="list-unstyled">
              <li>
                <a href="https://www.instagram.com/investors.coach" target='novo' className="d-flex align-items-center"
                  ><img
                    src="images/instagram.png"
                    width="16"
                    className="img-fluid me-2"
                    alt="InvestorCoach Instagram"
                  />
                  Instagram</a
                >
              </li>
              <li>
                <a href="https://www.facebook.com/investors.coach.web/" target='novo' className="d-flex align-items-center"
                  ><img
                    src="images/facebook.png"
                    width="16"
                    className="img-fluid me-2"
                    alt="InvestorCoach Facebook"
                  />
                  Facebook</a
                >
              </li>
              <li>
                <a href="https://www.linkedin.com/company/101745950/" target='novo' className="d-flex align-items-center"
                  ><img
                    src="images/linkedIn.png"
                    width="16"
                    className="img-fluid me-2"
                    alt="InvestorCoach LinkedIn"
                  />
                  LinkedIn</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div
          className="d-flex fs-8 justify-content-between flex-column-reverse flex-md-row gap-3"
        >
          <span> © {currentYear} Investors Coach. All rights reserved. </span>
          <div className="d-flex">
            <a href="/privacy" className="me-3">Privacy Policy</a>
            <a href="/terms">Terms of Use</a>
          </div>
        </div>
        </>
  );
}

export default FooterLast;