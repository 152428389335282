import React, { useState } from "react";
import {
  Children,
  SidebarContainer,
  SidebarWrapper,
  SidebarLogoWrapper,
  SidebarLogo,
  SidebarBrand,
  SidebarToggler,
} from "./SidebarStyles";
import BrandLogo from "./logoIcoach.png";

import { SidebarItems } from "..";

const MOBILE_VIEW = window.innerWidth < 468;

export default function Sidebar({ children, hover_active }) {
  const [displaysidebar, setdisplaysidebar] = useState(!MOBILE_VIEW);

  const handleSidebarDisplay = (e) => {
    e.preventDefault();
    if (window.innerWidth > 468) {
      setdisplaysidebar(!displaysidebar);
    } else {
      setdisplaysidebar(false);
    }
  };

  return (
    <React.Fragment>
<SidebarContainer displaysidebar={displaysidebar.toString()} hover_active={hover_active.toString()}>
  <SidebarWrapper>
    <SidebarLogoWrapper displaysidebar={displaysidebar.toString()}>
      <SidebarLogo href="#">
        <span className="app-brand-logo demo">
          <img src={BrandLogo} alt="Brand logo" />
        </span>
        <SidebarBrand
          displaysidebar={displaysidebar.toString()}
          className="app__brand__text"
        >
          Frest
        </SidebarBrand>
      </SidebarLogo>
      <SidebarToggler
        displaysidebar={displaysidebar.toString()}
        onClick={handleSidebarDisplay}
      >
        <img src={BrandLogo} alt="Brand logo" className="logo_mini" />
      </SidebarToggler>
    </SidebarLogoWrapper>
    <SidebarItems displaysidebar={displaysidebar.toString()} hover_active={hover_active.toString()} />
  </SidebarWrapper>
</SidebarContainer>
<Children displaysidebar={displaysidebar.toString()}>{children}</Children>
    </React.Fragment>
  );
}
