import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import HeaderComponent from './HeaderComponent';
import HeaderComponentSimple from './HeaderComponentSimple';
import FooterComponent from './FooterComponent';
import FooterGettouchComponent from './FooterGettouchComponent';
import FooterComponentSimple from './FooterComponentSimple';

function Layout({ children, FooterType, HeaderType }) {
  let Footer;
  let Header;
  
  switch (FooterType) {
      case '0':
        Footer = <FooterComponent />;
        break;    
      case '1':
        Footer = <FooterGettouchComponent />;
        break;
      case '2':
        Footer = <FooterComponentSimple />;
        break;
      default:
        break;
    }

    switch (HeaderType) {
      case '0':
        Header = <HeaderComponentSimple />;
        break;    
      case '1':
        Header = <HeaderComponent />;
        break;
      default:
        Header = "";
          break;
    }

  return (
    <div className="Layout">
      {Header}
      <main>{children}</main>
      {Footer}
    </div>
  );
}

// Add PropTypes validation
Layout.propTypes = {
  children: PropTypes.node.isRequired, // Children should be any valid React node
  FooterType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // FooterType can be a string or number
  HeaderType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // HeaderType can be a string or number
};

export default Layout;