// src/components/FooterComponent.js
import React from 'react';

const currentYear = new Date().getFullYear();

function FooterLastSimple() {
  return (
        <>        
        <div
          className="d-flex fs-8 justify-content-between flex-column-reverse flex-md-row gap-3"
        >
          <span> © {currentYear} Investors Coach. All rights reserved. </span>
          <div className="d-flex">
            <a href="/privacy" target='new' className="me-3">Privacy Policy</a>
            <a href="/terms" target='new'>Terms of Use</a>
          </div>
        </div>
        </>
  );
}

export default FooterLastSimple;