// src/components/HeaderComponent.js
import React from 'react';
import { HashLink } from 'react-router-hash-link';

function HeaderComponent() {
  return (
    <>
    <div
      className="modal fade drawer-mobile"
      id="drawerMobile"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div
            className="py-4 d-flex align-items-center justify-content-between mb-3"
          >
            <a href="/"><img src="images/logo.png" width="228" alt="Investors Coach" /></a>

            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <ul className="list-unstyled">
            <li>
              <HashLink smooth to="/#experts">Find an Expert</HashLink>
            </li>
            <li>
              <a href="/about">About Us</a>
            </li>
            <li>
              <a href="/faq">FAQs</a>
            </li>
            <li>
              <a href="/contact-us">Contact Us</a>
            </li>
            <li>
              <a className="btn btn-primary fs-7" href="/become-an-expert"> Become an Expert </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <header>
      <nav className="navbar navbar-expand-lg py-3">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="images/logo.png" width="228" alt="Investors Coach" />
          </a>
          <span
            className="d-block d-lg-none"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#drawerMobile"
          >
            <img src="images/menu.svg" alt="Menu Icon" />
          </span>
          <div className="collapse navbar-collapse" id="navbarInvestorCoach">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-2 gap-lg-3">
              <li className="nav-item">
                <HashLink className="nav-link" smooth to="/#experts">Find an Expert</HashLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/about">About Us</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/faq">FAQs</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact-us">Contact Us</a>
              </li>
              <li className="nav-item">
                <a className="btn btn-primary fs-7" href="/become-an-expert"> Become an Expert </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
    </>
  );
}

export default HeaderComponent;