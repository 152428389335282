// src/components/FooterComponent.js
import React from 'react';
import FooterLast from './FooterLast';

function FooterGettouchComponent() {
  return (
    <div className="footer mx-md-4 mt-md-5 mt-0 pb-4 mb-3">
      <div className="container">

      <div className="p-4 p-md-5 mb-5 rounded-5 bg-dark text-white">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6">
              <h2 className="fw-bold fs-1 mb-3">Let’s get in touch</h2>
              <p className="mb-5">
              We’re here to help with any questions and support you need on your real estate journey.
              </p>
              <a
                href="mailto:letschat@investors.coach"
                className="link-primary fs-5 fw-bold"
                >letschat@investors.coach</a
              >
            </div>
          </div>
        </div>
        <FooterLast />
      </div>
    </div>
  );
}

export default FooterGettouchComponent;