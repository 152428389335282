import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  ItemsList,
  ItemContainer,
  ItemWrapper,
  ItemName,
} from "./SidebarStyles";

import { sidebarData } from "..";

const SidebarItems = ({ displaySidebar, hover_active }) => {
  const [activeItem, setActiveItem] = useState(0);
  
  return (
    <ItemsList>
      {sidebarData.map((itemData, index) => (
        <ItemContainer
          key={index}
          onClick={() => setActiveItem(itemData.id)}
          className={itemData.id === activeItem ? "active" : ""}
        >
          {hover_active === "true" ? (
            <Link to={itemData.path}>
              <ItemWrapper>
                {itemData.icon}
                <ItemName displaySidebar={displaySidebar}>
                  {itemData.name}
                </ItemName>
              </ItemWrapper>
            </Link>
          ) : (
            <ItemWrapper>
              {itemData.icon}
              <ItemName displaySidebar={displaySidebar}>
                {itemData.name}
              </ItemName>
            </ItemWrapper>
          )}
        </ItemContainer>
      ))}
    </ItemsList>
  );
};

export default SidebarItems;