import React from 'react';
import FooterLastSimple from './FooterLastSimple';

function FooterComponentSimple() {
  return (
    <>
    <div  className="footer mx-md-4 mt-md-5 mt-0 pb-4 mb-3">
      <div className="container">
        <FooterLastSimple />
      </div>
    </div>
    </>
  );
}

export default FooterComponentSimple;