import React, { useState } from 'react';
import FooterLast from './FooterLast';
import { Modal } from 'bootstrap'; // Import Bootstrap JS

function FooterComponent() {
  const [email, setEmail] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSignUp = () => {
    if (!validateEmail(email)) {
      openErrorModal("Please enter a valid email address.");
      return;
    }

    const formData = new URLSearchParams();
    formData.append('email_address', email);
    formData.append('form-type', 'subscribe');
    formData.append('counter', '0');

    fetch('https://api.investors.coach/add_mailchimp', {
      method: 'POST',
      headers: {
        'accept': '*/*',
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'origin': 'https://investors.coach',
        'referer': 'https://investors.coach/',
        'sec-ch-ua': '"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"',
        'sec-ch-ua-mobile': '?0',
        'sec-ch-ua-platform': '"macOS"',
        'user-agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36'
      },
      body: formData.toString()
    })
    .then(response => {
      if (response.ok) {
        setEmail(''); // Clear the email input field
        openSuccessModal(); // Trigger the success modal
      } else {
        openErrorModal("Failed to sign up. Please try again."); // Trigger the error modal
      }
    })
    .catch(error => {
      console.error('Error during sign up:', error);
      openErrorModal("An error occurred. Please try again later."); // Trigger the error modal
    });
  };

  // Function to initialize and show the success modal
  function openSuccessModal() {
    const modalSuccess = document.getElementById('successModal');
    const modalInstance = new Modal(modalSuccess);
    modalInstance.show();
  }

  // Function to initialize and show the error modal
  function openErrorModal(message) {
    const modalError = document.getElementById('errorModal');
    modalError.querySelector('.modal-body p').innerText = message;
    const modalInstance = new Modal(modalError);
    modalInstance.show();
  }

  return (
    <>
      <div className="footer mx-md-4 mt-md-5 mt-0 pb-4 mb-3">
        <div className="container">
          <div className="p-4 p-md-5 mb-5 rounded-5 bg-dark text-white">
            <div className="row">
              <div className="col-lg-6 text-center text-lg-start">
                <h2 className="fw-bold fs-1 mb-3">
                  Sign up now and get $10 off your first session!
                </h2>
                <p className="fw-light mb-4 mb-md-5">
                  Experience expert guidance and unlock new opportunities in real estate. Don’t miss out on this exclusive offer!
                </p>
                <div className="row d-flex align-items-center flex-column flex-md-row">
                  <div className="col-md-auto">
                    <input
                      className="form-control form-control-lg"
                      type="email"
                      placeholder="Enter your Email"
                      aria-label="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-md-auto d-grid mt-2 mt-md-0">
                    <button onClick={handleSignUp} className="btn btn-lg btn-primary flex-shrink-0">
                      Sign Up Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 offset-lg-1 d-none d-lg-block">
                <img
                  src="images/sign-up-now.png"
                  className="img-fluid"
                  alt="Sign Up Now"
                />
              </div>
            </div>
          </div>

          <FooterLast />
        </div>
      </div>

      {/* Success Modal */}
      <div
        className="modal fade"
        id="successModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-4">
              <p className="fw-bold fs-5 mb-2">Success!</p>
              <span className="fs-7 text-secondary">
                Thank you for signing up! You&apos;ve successfully subscribed to our newsletter and will receive $10 off your first session.
              </span>
              <div className="d-flex align-items-center justify-content-end mt-4 gap-2">
                <button
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Error Modal */}
      <div
        className="modal fade"
        id="errorModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-4">
              <p className="fw-bold fs-5 mb-2">Error</p>
              <p className="fs-7 text-secondary"></p>
              <div className="d-flex align-items-center justify-content-end mt-4 gap-2">
                <button
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterComponent;