import React, { Suspense, lazy, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import PrivateRoute from './components/PrivateRoute';  // Import PrivateRoute

import './scss/styles.scss'; // Import your SCSS styles
import { DynamicItem, Sidebar, sidebarData } from "./components";

const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Faq = lazy(() => import('./pages/Faqs'));
const Contactus = lazy(() => import('./pages/Contactus'));
const BecomeExpert = lazy(() => import('./pages/Become-an-expert'));
const Payment = lazy(() => import('./pages/Payment'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Terms = lazy(() => import('./pages/Terms_servs'));
const Expert = lazy(() => import('./pages/Expert'));
const Onboarding = lazy(() => import('./pages/admin-experts/onboarding'));

function App() {
  const [isAuthenticated] = useState(true); 
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={
              <Layout FooterType="0" HeaderType="1">
                <Home />
              </Layout>
            }
          />
          <Route
            path="/about"
            element={
              <Layout FooterType="0" HeaderType="1">
                <About />
              </Layout>
            }
          />
          <Route
            path="/faq"
            element={
              <Layout FooterType="0" HeaderType="1">
                <Faq />
              </Layout>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Layout FooterType="1" HeaderType="1">
                <Contactus />
              </Layout>
            }
          />
          <Route
            path="/become-an-expert"
            element={
              <Layout FooterType="1" HeaderType="1">
                <BecomeExpert />
              </Layout>
            }
          />
          <Route
            path="/privacy"
            element={
              <Layout FooterType="1" HeaderType="0">
                <Privacy />
              </Layout>
            }
          />
          <Route
            path="/terms"
            element={
              <Layout FooterType="1" HeaderType="0">
                <Terms />
              </Layout>
            }
          />
          <Route
            path="*"
            element={
              <Layout FooterType="2" HeaderType="0">
                <Expert />
              </Layout>
            }
          />
          <Route
            path="/confirm-payment"
            element={
              <Payment />
            }
          />

          {/* Keep the root admin-experts route open */}
          <Route
            path="/admin-experts/onboarding"
            element={
              <Sidebar hover_active={false}>
                <Onboarding />
              </Sidebar>
            }
          />
          <Route
            path="/admin-experts"
            element={
                <DynamicItem page="homepage" />
            }
          />
            {/* Admin Experts Route */}
            <Route
            path="/admin-experts/*"
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                <Sidebar hover_active={true}>
                  <Routes>
                    <Route path="" element={<DynamicItem page="homepage" />} />
                    {sidebarData &&
                      sidebarData.map((item, index) => (
                        <Route
                          key={index}
                          path={item.path}
                          element={<DynamicItem page={item.name} />}
                        />
                      ))}
                  </Routes>
                </Sidebar>
              </PrivateRoute>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;